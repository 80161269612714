import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import { Container, Row, Col, Stack } from "react-bootstrap";
import { StructuredText } from "react-datocms";
import iconImage from "../assets/images/icon.avif"

const careers = ({ data }) => {
  console.log(data)
  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common careers">
        <h1>{data.allDatoCmsCareer.nodes[0].slugPage.title}</h1>
        <Stack className="card-long mb-4">
          <img alt="card-long-first" src={data.allDatoCmsCareer.nodes[0].bannerHero[0].background.url} />
          <div className='back-image  career-image'>
            <h4>{data.allDatoCmsCareer.nodes[0].bannerHero[0].description}</h4>
            <p>{data.allDatoCmsCareer.nodes[0].bannerHero[0].text}</p>
          </div>
        </Stack>
        <Row>
          <Col xs={12} lg={8}>
            {
              data.allDatoCmsCareer.nodes[0].titleAndParagraph.map((titleAndParagraph, index) =>
                <div key={index} >
                  <h4>{titleAndParagraph.title}</h4>
                  <StructuredText data={titleAndParagraph.description.value} />
                </div>
              )
            }
            {/* <a href="mailto:careers@digitaltradingcards.com">Careers</a> */}
          </Col>
          <Col xs={12} lg={4}>
            <img alt="contact Us" className='d-none-max-991' style={{width: '100%'}} src={iconImage} />
          </Col>
        </Row>
        <div className="list-careers" dangerouslySetInnerHTML={{ __html: data.allDatoCmsCareer.nodes[0].items }}></div>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsCareer.nodes[0].contactUs }}></p>
            <span dangerouslySetInnerHTML={{ __html: data.allDatoCmsCareer.nodes[0].weCreate }}></span>
          </Col>
          <Col className='text-center' xs={12} md={6} lg={4}>
            <img alt="contact Us" src={data.allDatoCmsCareer.nodes[0].image.url} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default careers

export const Head = ({ data }) => (
  <Seo title="Careers"
    description={data.allDatoCmsCareer.nodes[0].seo.description}
    image={data.allDatoCmsCareer.nodes[0].seo.image.url}
  />
)

export const query = graphql`
query CareersQuery {
  allDatoCmsCareer {
    nodes {
      seoMetaTags {
       tags
      }
     seo {
       description
       twitterCard
       image {
         id
       }
       title
     }
     slugPage {
      title
      slug
    }
    bannerHero {
      background {
        url
      }
      description
      logo {
        url
      }
      text
    }
    titleAndParagraph {
      title
      description {
        value
      }
    }
    items
    contactUs
    weCreate
    image {
      url
    }
    socialShare {
      url
    }
   }
  }
}`